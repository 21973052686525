<template>

<app-table :empty="!collection.length" emptyMessage="Not in any teams.">

	<app-table-head :layout="layout">

		<app-table-column text="Level" />
		<app-table-column text="Organization" />

	</app-table-head>

	<app-table-body>

		<app-table-row align="top" :layout="layout" v-for="item in collection" :key="item.id">

			<app-table-cell-text :text="$constants.team.members.typeName[item.type]" />
			<app-table-cell-text :text="item.organisation" />

		</app-table-row>

	</app-table-body>

</app-table>

</template>

<script>

export default {

	props: ['collection'],

	data: function() {

		return {
			layout: '120px auto'
		}

	}

}

</script>

<style scoped>

</style>